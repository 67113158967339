@font-face {
  font-family: PingFangTC-Semibold;
  src: url("./assets/PingFang-TC-Semibold-2.otf");
}

@font-face {
  font-family: PingFangTC-Regular;
  src: url("./assets/PingFang-TC-Regular-2.otf");
}

@font-face {
  font-family: PingFangTC-Medium;
  src: url("./assets/PingFang-TC-Medium-2.otf");
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #ccc;
}

body{
  font-family: PingFangTC-Regular;
}

.container_transform_root{
  position: relative;
  margin: auto;
  max-width: 750px;
}

.container_transform-enter {
  opacity: 0;
  transform: translateX(100%);
}
.container_transform-enter-active {
  opacity: 1;
  position: relative;
  transform: translateX(0);
  transition: .3s;
}
.container_transform-exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: translateX(0);
}
.container_transform-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: .3s;
}

.qrcode_transform-enter {
  opacity: 0;
  transform: scale(1.1);
}
.qrcode_transform-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.qrcode_transform-exit {
  opacity: 1;
}
.qrcode_transform-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transition: opacity 300ms, transform 300ms;
}